/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

h1 {
  color: #333;
  text-align: center;
}

input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  display: block;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
  width: 300px;
  transition: transform 0.2s;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
}

.card h2 {
  color: #0077cc;
}

.card p {
  color: #666;
}
