/* Existing HomePage Styles */
.container {
  padding: 20px;
  text-align: center;
  background-color: #ffe7d8; /* Apply background color from logo */
  min-height: 100vh; /* Ensure container fills the viewport height */
}


.logo {
  width: 100px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.title {
  font-size: 2.5em;
  margin: 20px 0;
  color: #ff914d; /* Use contrasting color */
}

.searchBar {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 500px;
  border: 2px solid #FF914D; /* Use main color for input border */
  border-radius: 5px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  background-color: white;
  border: 3.5px solid grey; /* Default border color */
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  width: 300px;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card h2 {
  color: #FF914D; /* Title in main color */
}

.card p {
  color: #333;
}

/* Affiliation Filters */
.affiliationFilters {
  margin: 20px 0;
}

.filterButton {
  padding: 10px 20px;
  margin: 0 10px;
  border: 2px solid white;
  background-color: white;
  color: #FF914D;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 18px;
}

.filterButton.active {
  background-color: #FF914D;
  color: white;
}

.filterButton:hover {
  background-color: #FF914D;
  color: white;
}

/* Category Dropdown */
.categoryFilter {
  margin-bottom: 20px;
}

.categoryFilter label {
  margin-right: 10px;
  font-weight: bold;
  color: #FF914D;
}

.categoryFilter select {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #FF914D;
  border-radius: 5px;
}

/* New Styles for OrganizationPage */

/* Full container to cover entire page */
.fullContainer {
  padding: 50px 20px;
  text-align: center;
  background-color: #FF914D;
  min-height: 100vh; /* Ensure the background color covers the full viewport height */
}

/* Card with border based on affiliation */
.orgCard {
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  max-width: 800px;
  border-width: 3px;
  border-style: solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* Organization Title */
.orgTitle {
  font-size: 2.5em;
  color: #FF9554;
  margin-bottom: 20px;
}

/* Description Styling */
.orgDescription {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 20px;
}

/* Detail Line Styling */
.orgDetail {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 10px;
}

/* Button to visit website */
.websiteButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FF9554;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.websiteButton:hover {
  background-color: #ff7a29; /* Darker shade of the main color for hover effect */
}
